import React from 'react';
import './about.css';
import Profile from '../../assets/Latifa Yari.jpg';

function About() {
  return (
    <section id="about" className="about-container">
      <div className="about-profile-container">
        <img className="profile" src={Profile} alt="profile-pic" />
      </div>
      <div className="about-text-icon-container">
        <h2 className="text">
          <span className="emily-clark">
            About
            <span className="neonText"> Me</span>
          </span>
          <br />
          Data Analyst | Storyteller with a Curious Mind
        </h2>
        <p className="text description-text">
          I turn raw data into clear, actionable insights. With tools like
          <b> SQL</b>, <b>Python</b>, <b>R</b>, <b>Excel</b>, <b>Power BI</b>,
          <b>Tableau</b>, and <b>Stata</b>, I specialize in cleaning data,
          exploring trends, and supporting informed decisions.
        </p>
        <p className="text description-text">
          I’m also growing in <b>predictive modeling and machine learning</b>,
          working toward a future in data science. I don’t assume — I let the
          data speak.
        </p>
        <a href="/LatifaYariResume.pdf" download>
          <button className="download-button">Download CV</button>
        </a>
      </div>
    </section>
  );
}

export default About;
