import React from 'react';
import './home.css';
import Profile from '../../assets/Latifa Yari.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Home() {
  return (
    <section id="home" className="home-container">
      <div className="profile-container">
        <img className="profile" src={Profile} alt="profile-pic" />
      </div>
      <div className="text-icon-container">
        <h2 className="text">
          Hello, I'm
          <br />
          <span className="emily-clark">Latifa Yari</span>
          <br />
          And I'm a{' '}
          <span className="neonText">
            Data Analyst | Pattern Seeker | Storyteller with a Curious Mind
          </span>
        </h2>
        <p className="text description-text">
          To me, working with data is an art. I don’t just run numbers — I
          uncover the stories they’ve been holding all along. My journey started
          in 2016 with an R class that opened my eyes to how data speaks. Since
          then, I’ve been turning messy datasets into meaningful insights with
          SQL, Python, R, and Excel — always curious, always listening.
        </p>
        <p className="text description-text">
          I approach every project with an open mind and a sharp eye for detail,
          letting the data guide me — not assumptions. My favorite moment? When
          someone looks at the analysis and says, “I never realized this was in
          the data.”
        </p>
        <div className="media">
          <div>
            <FontAwesomeIcon className="neon-icon" icon={faPhone} /> +1 309 997
            0481
          </div>
          <div>
            {' '}
            <FontAwesomeIcon className="neon-icon" icon={faEnvelope} />{' '}
            yarilatifa@gmail.com
          </div>

          <a
            href="https://www.linkedin.com/in/latifa-yari/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="neon-icon" icon={faLinkedin} />
          </a>
        </div>
        <a href="/LatifaYariResume.pdf" download>
          <button className="download-button">Download CV</button>
        </a>
      </div>
    </section>
  );
}

export default Home;
