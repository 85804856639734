import React, { useState, useEffect, useRef } from 'react';
import './projects.css';
import ProjectOne from '../../assets/us_house.png';
import ProjectTwo from '../../assets/world_life.png';
import ProjectThree from '../../assets/bitcoin.png';
import ProjectFour from '../../assets/electricity.png';

function Projects() {
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const scrollPositionRef = useRef(0);

  // More robust scroll lock implementation
  useEffect(() => {
    if (showModal) {
      // Store current scroll position
      scrollPositionRef.current = window.pageYOffset;

      // Apply styles to lock scrolling
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPositionRef.current}px`;
      document.body.style.width = '100%';
    } else {
      // Restore scrolling and position
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';

      // Restore scroll position
      if (scrollPositionRef.current) {
        window.scrollTo(0, scrollPositionRef.current);
      }
    }

    return () => {
      // Cleanup function to restore scrolling on unmount
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      if (showModal && scrollPositionRef.current) {
        window.scrollTo(0, scrollPositionRef.current);
      }
    };
  }, [showModal]);

  const projects = [
    {
      id: 1,
      title: 'US Household Income Analysis',
      image: ProjectOne,
      overview:
        'This project involved cleaning and preparing U.S. household income data using MySQL. The objective was to address data inconsistencies and lay the groundwork for deeper analysis of income trends across states, cities, and area types.',
      responsibilities: [
        'Data Cleaning: Identified and rectified duplicates and inconsistencies within the dataset using SQL queries.',
        'Database Preparation: Organized and structured the dataset to be analysis-ready for future exploratory or visualization tasks.',
        'Technologies Used: MySQL',
      ],
      outcome:
        'The cleaned and structured dataset is now ready for future exploratory data analysis and visualization — a crucial first step in uncovering regional economic disparities.',
      link: 'https://github.com/latifa-yari/US-Household-Income-Analysis',
    },
    {
      id: 2,
      title: 'World Life Expectancy Data Analysis',
      image: ProjectTwo,
      overview:
        'This project involved cleaning and exploring the World Life Expectancy dataset using SQL. The goal was to prepare the data for meaningful insights by resolving inconsistencies and conducting an initial exploratory analysis of global health trends.',
      responsibilities: [
        'Data Cleaning: Identified and addressed missing values, standardized data entries, and ensured data consistency using SQL.',
        'Exploratory Data Analysis (EDA): Performed SQL-based queries to explore life expectancy patterns across countries and over time.',
        'Technologies Used: MYSQL',
      ],
      outcome:
        'The structured and cleaned dataset highlighted key differences in life expectancy globally, setting the stage for future visual or statistical analysis.',
      link: 'https://github.com/latifa-yari/World_Life_Expectancy',
    },
    {
      id: 3,
      title: 'Forecasting Financial Volatility Using ARCH & GARCH Models',
      image: ProjectThree,
      overview:
        'This project applied Autoregressive Conditional Heteroskedasticity (ARCH) and Generalized ARCH (GARCH) models to analyze and forecast financial market volatility using historical time series data. The objective was to understand time-varying volatility patterns and assess their implications for financial risk management.',
      responsibilities: [
        'Model Development: Implemented ARCH and GARCH models in Python to model volatility clustering in time series data.',
        'Data Analysis: Used historical financial datasets to evaluate the behavior of asset returns and volatility shocks.',
        'Interpretation & Insights: Interpreted model results to identify periods of heightened volatility, improving understanding of market dynamics.',
        'Technologies Used: Python (statsmodels, arch, NumPy, Pandas, Matplotlib)',
      ],
      outcome:
        'The project demonstrated the effectiveness of ARCH/GARCH models in forecasting market risk. Results indicated how volatility shocks propagate over time, offering valuable tools for analysts and risk managers in finance.',
      link: 'https://github.com/latifa-yari/Forecasting-Financial-Volatility-Using-ARCH-GARCH-Models',
    },
    {
      id: 4,
      title: 'Time Series Forecasting with ARIMA & SARIMA Models',
      image: ProjectFour,
      overview:
        'This project focused on implementing AutoRegressive Integrated Moving Average (ARIMA) and Seasonal ARIMA (SARIMA) models to forecast time series data in economic and financial contexts. It aimed to capture both trend and seasonal components to make accurate future predictions.',
      responsibilities: [
        'Model Development: Built and fine-tuned ARIMA and SARIMA models to understand time series behavior and forecast future values.',
        'Data Preprocessing: Transformed and differenced time series data to meet model assumptions, ensuring stationarity.',
        'Evaluation: Assessed model performance using diagnostics like AIC/BIC and residual plots, refining parameters through grid search.',
        'Technologies Used: Python (pmdarima, Pandas, Matplotlib)',
      ],
      outcome:
        'The project demonstrated the strength of ARIMA and SARIMA models in modeling seasonality and trends, making them effective tools for time series forecasting in business and finance.',
      link: 'https://github.com/latifa-yari/Time-Series-Forecasting-ARIMA-and-SARIMA-Models',
    },
  ];

  const openModal = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedProject(null);
  };

  return (
    <section id="projects" className="projects-container">
      <h2>
        Latest <span className="neonText">Projects</span>
      </h2>
      <div className="projects-box-container">
        {projects.map((project) => (
          <div className="project-box" key={project.id}>
            <span className="service">{project.title}</span>
            <img
              className="project-img"
              src={project.image}
              alt={`Project-${project.id}`}
            />
            <button className="details" onClick={() => openModal(project)}>
              Details
            </button>
          </div>
        ))}
      </div>

      {/* Modal */}
      {showModal && selectedProject && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              ×
            </button>
            <h3>{selectedProject.title}</h3>

            <div className="modal-section">
              <h4>Overview:</h4>
              <p>{selectedProject.overview}</p>
            </div>

            <div className="modal-section">
              <h4>Key Responsibilities:</h4>
              <ul>
                {selectedProject.responsibilities.map((resp, index) => (
                  <li key={index}>{resp}</li>
                ))}
              </ul>
            </div>

            <div className="modal-section">
              <h4>Outcome:</h4>
              <p>{selectedProject.outcome}</p>
            </div>

            <div className="modal-section">
              <h4>Link to the Project:</h4>
              <a
                href={selectedProject.link}
                className="project-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                View on GitHub
              </a>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Projects;
