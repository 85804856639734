import React from 'react';
import './services.css';
import DataVis from '../../assets/data-vis.png';

function Services() {
  return (
    <section id="services" className="services-container">
      <h2>
        Our <span className="neonText">Services</span>
      </h2>
      <div className="box-container">
        <div className="box">
          <img className="service-icon" src={DataVis} alt="Icon" />
          <p className="description">
            <span className="service">Data Visualizations</span>
            <br />
            <span className="service-description">
              Crafting clear, interactive dashboards that turn numbers into
              stories — helping you see patterns, trends, and insights at a
              glance.
            </span>
          </p>
        </div>
        <div className="box">
          <img className="service-icon" src={DataVis} alt="Icon" />
          <p className="description">
            <span className="service">Data Analysis</span>
            <br />
            <span className="service-description">
              Cleaning, organizing, and exploring datasets to uncover meaningful
              insights and support smarter, evidence-based decisions.
            </span>
          </p>
        </div>
        <div className="box">
          <img className="service-icon" src={DataVis} alt="Icon" />
          <p className="description">
            <span className="service">Statistical Modeling </span>
            <br />
            <span className="service-description">
              Using statistical techniques and machine learning to forecast
              outcomes, reveal relationships, and power strategic choices.
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
